import { useState, useEffect, useMemo, useContext } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router';
import jwt from 'jwt-decode';
import { MutationCache, QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { BrowserRouter } from 'react-router';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import { I18nProvider } from '@cloudscape-design/components/i18n';
import enMessages from '@cloudscape-design/components/i18n/messages/all.en';
import queryString from 'query-string';
import * as Sentry from '@sentry/react';
import { AxiosError } from 'axios';

import { getErrorDetails } from './common/loading';
import { LocalTopNavigation, NavigationContext } from './common/navigation';
import { UserTokenInfo } from './common/types';
import UserContext from './common/user';
import Dashboard from './dashboard';
import Login from './login';
import SignupConfirmation from './signup/confirmation';
import PayoutsSetup from './payouts/setup';
import Logout from './logout';
import Invite from './manage/invite';
import UsersManage from './manage/users';
import ErrorPage from './error';
import Reset from './reset';
import ResetChange from './reset/change';
import AccountPage from './account';
import UpdateAccountPage from './account/update';
import {
  RequireSomePermissionPrefix,
  RequireAuth,
  RequireNoAuth,
  RequirePermissions,
} from './common/auth';
import UserManage from './manage/users/user';
import AuthDashboard from './manage/auth';
import RoleManage from './manage/auth/role';
import OrdersPage from './orders';
import Homepage from './home';
import ReportPage from './report';
import DevicesPage from './devices';
import DevicePage from './devices/device';
import VenuesPage from './venues';
import VenuePage from './venues/venue';
import Chat from './chat';
import History from './history';
import Access from './access';

import '@cloudscape-design/global-styles/index.css';
import './global.css';

import { liveApiUrl } from './common/api/axiosInstance';
import { useLocalStorage } from 'react-use';

Sentry.init({
  dsn: 'https://08038028ed370c999c8ccaa7d28bc7b2@o4507625924722688.ingest.us.sentry.io/4507627326013440',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: process.env.NODE_ENV === 'development' ? 0.0 : 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', liveApiUrl],
  // Session Replay
  replaysSessionSampleRate: 0.0,
  replaysOnErrorSampleRate: process.env.NODE_ENV === 'development' ? 0.0 : 1.0,
  environment: process.env.NODE_ENV === 'development' ? 'development' : 'production',
  enabled: process.env.NODE_ENV === 'development' ? false : true,
});

function AppWithUserContext() {
  const navigate = useNavigate();
  const { innerWidth: width } = window;
  const [navigationOpen, setNavigationOpen] = useState(width > 688);
  const navigationSize = 200;
  const { pathname, hash, key, search } = useLocation();

  const { user } = useContext(UserContext);

  const isAuthenticated = !!user;

  useEffect(() => {
    if (hash !== '') {
      setTimeout(() => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);

        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }, 0);
    } else if (!search) {
      window.scrollTo(0, 0);
    }
  }, [pathname, hash, key]); // do this on route change

  useEffect(() => {
    if (user) {
      Sentry.setUser({
        id: user.id,
        email: user.email,
      });
    }
  }, [user]);

  const queryClient = useMemo(() => {
    return new QueryClient({
      defaultOptions: {
        queries: {
          refetchOnWindowFocus: false,
        },
      },
      queryCache: new QueryCache({
        onError: async (error, query) => {
          if (
            error instanceof AxiosError &&
            error.response &&
            (error.response.status === 401 || error.response.status === 403) &&
            isAuthenticated
          ) {
            // Logout user, needs to re-login because of expired refresh token
            navigate('/logout');

            return;
          }

          const { title, description } = getErrorDetails(error);
          // Report the error to Sentry
          Sentry.captureException(error, {
            tags: {
              queryKey: `${query.queryKey}`,
              title,
              description,
            },
          });
        },
      }),
      mutationCache: new MutationCache({
        onError: async (error) => {
          if (
            error instanceof AxiosError &&
            error.response &&
            (error.response.status === 401 || error.response.status === 403)
          ) {
            if (isAuthenticated) {
              // Logout user, needs to re-login because of expired refresh token
              navigate('/logout');
            }

            // Otherwise, the user is not authenticated, so we don't need to do anything - just failed a login
            // attempt
            return;
          }

          const { title, description } = getErrorDetails(error);
          // Report the error to Sentry
          Sentry.captureException(error, {
            tags: {
              title,
              description,
            },
          });
        },
      }),
    });
  }, [isAuthenticated]);

  return (
    <Sentry.ErrorBoundary
      fallback={
        // TODO: Add a more custom error page
        <ErrorPage />
      }
    >
      <QueryClientProvider client={queryClient}>
        <LocalTopNavigation />
        <NavigationContext.Provider value={{ navigationOpen, setNavigationOpen, navigationSize }}>
          <Routes>
            <Route element={<ErrorPage />} path="*" />
            <Route element={<Homepage />} path="/" />
            <Route path="/account">
              <Route
                element={
                  <RequireAuth>
                    <AccountPage />
                  </RequireAuth>
                }
                index
              />
              <Route
                element={
                  <RequireAuth>
                    <UpdateAccountPage />
                  </RequireAuth>
                }
                path="update"
              />
            </Route>
            <Route
              element={
                <RequireNoAuth>
                  <Login />
                </RequireNoAuth>
              }
              path="/login"
            />
            <Route path="/signup">
              <Route
                element={
                  <RequireNoAuth>
                    <SignupConfirmation />
                  </RequireNoAuth>
                }
                path="confirmation"
              />
            </Route>
            <Route path="/payouts">
              <Route element={<PayoutsSetup />} path="setup" />
            </Route>
            <Route
              element={
                <RequireSomePermissionPrefix permissions={['orders.get', 'orders.venue.']}>
                  <Dashboard />
                </RequireSomePermissionPrefix>
              }
              path="/dashboard"
            />
            <Route path="/orders">
              <Route
                element={
                  <RequireSomePermissionPrefix permissions={['orders.get', 'orders.venue.']}>
                    <OrdersPage />
                  </RequireSomePermissionPrefix>
                }
                index
              />
            </Route>
            <Route path="/devices">
              <Route
                element={
                  <RequireSomePermissionPrefix permissions={['orders.get', 'orders.venue.']}>
                    <DevicesPage />
                  </RequireSomePermissionPrefix>
                }
                index
              />
              <Route
                element={
                  <RequireSomePermissionPrefix permissions={['orders.get', 'orders.venue.']}>
                    <DevicePage />
                  </RequireSomePermissionPrefix>
                }
                path=":deviceId"
              />
            </Route>
            <Route path="/venues">
              <Route
                element={
                  <RequireSomePermissionPrefix permissions={['orders.get', 'orders.venue.']}>
                    <VenuesPage />
                  </RequireSomePermissionPrefix>
                }
                index
              />
              <Route
                element={
                  <RequireSomePermissionPrefix permissions={['orders.get', 'orders.venue.']}>
                    <VenuePage />
                  </RequireSomePermissionPrefix>
                }
                path=":venueId"
              />
            </Route>
            <Route path="/report">
              <Route
                element={
                  <RequireSomePermissionPrefix permissions={['orders.get', 'orders.venue.']}>
                    <ReportPage />
                  </RequireSomePermissionPrefix>
                }
                index
              />
            </Route>
            <Route element={<Logout />} path="/logout" />
            <Route path="/reset">
              <Route element={<Reset />} index />
              <Route element={<ResetChange />} path="change" />
            </Route>
            <Route
              element={
                <RequirePermissions permissions={['orders.get']}>
                  <Chat />
                </RequirePermissions>
              }
              path="/chat/:chatId?"
            />
            <Route element={<Access />} path="/access/:code" />
            <Route
              element={
                <RequirePermissions permissions={['orders.get']}>
                  <History />
                </RequirePermissions>
              }
              path="/history"
            />
            <Route path="manage">
              <Route
                element={
                  <RequirePermissions
                    permissions={['invites.create', 'invites.get', 'invites.delete']}
                  >
                    <Invite />
                  </RequirePermissions>
                }
                path="invites"
              />
              <Route path="users">
                <Route
                  element={
                    <RequirePermissions permissions={['users.get', 'users.roles.get']}>
                      <UsersManage />
                    </RequirePermissions>
                  }
                  index
                />
                <Route
                  element={
                    <RequirePermissions
                      permissions={[
                        'users.get',
                        'users.roles.add',
                        'users.roles.get',
                        'users.roles.delete',
                      ]}
                    >
                      <UserManage />
                    </RequirePermissions>
                  }
                  path=":userId"
                />
              </Route>
              <Route path="auth">
                <Route
                  element={
                    // TODO add permissions
                    <RequirePermissions permissions={['roles.create', 'permissions.create']}>
                      <AuthDashboard />
                    </RequirePermissions>
                  }
                  index
                />
                <Route
                  element={
                    <RequirePermissions permissions={['roles.create', 'permissions.create']}>
                      <RoleManage />
                    </RequirePermissions>
                  }
                  path="roles/:roleId"
                />
                {/* TODO additional auth views here */}
              </Route>
            </Route>
          </Routes>
        </NavigationContext.Provider>
      </QueryClientProvider>
    </Sentry.ErrorBoundary>
  );
}

function App() {
  // const localToken = localStorage.getItem('token');
  // const localRefreshToken = localStorage.getItem('refresh_token');

  const [localToken, setLocalToken, removeLocalToken] = useLocalStorage<string | null>(
    'token',
    null,
  );
  const [localRefreshToken, setLocalRefreshToken, removeLocalRefreshToken] = useLocalStorage<
    string | null
  >('refresh_token', null);

  const [user, setUser] = useState<UserTokenInfo | null>(
    localToken ? (jwt(localToken) as UserTokenInfo) : null,
  );
  const [token, setToken] = useState<string | null>(localToken ?? null);
  const [refreshToken, setRefreshToken] = useState<string | null>(localRefreshToken ?? null);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (localToken) {
      const jwtToken = jwt(localToken);
      setUser(jwtToken as UserTokenInfo);
    }
  }, [localToken]);

  useEffect(() => {
    if (token) {
      // localStorage.setItem('token', token);
      setLocalToken(token);
    } else {
      // localStorage.removeItem('token');
      removeLocalToken();
    }
  }, [token]);

  useEffect(() => {
    if (refreshToken) {
      // localStorage.setItem('refresh_token', refreshToken);
      setLocalRefreshToken(refreshToken);
    } else {
      // localStorage.removeItem('refresh_token');
      removeLocalRefreshToken();
    }
  }, [refreshToken]);

  return (
    <BrowserRouter>
      <UserContext.Provider
        value={{
          user,
          setUser,
          token,
          setToken,
          refreshToken,
          setRefreshToken,
          loading,
          setLoading,
        }}
      >
        <QueryParamProvider
          adapter={ReactRouter6Adapter}
          options={{
            searchStringToObject: queryString.parse,
            objectToSearchString: queryString.stringify,
            removeDefaultsFromUrl: true,
          }}
        >
          <I18nProvider locale="en" messages={[enMessages]}>
            <AppWithUserContext />
          </I18nProvider>
        </QueryParamProvider>
      </UserContext.Provider>
    </BrowserRouter>
  );
}

export default App;
